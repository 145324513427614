import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import { PlayerRoleEnum } from '@/enums/PlayerRole.enum';

interface IUsePlayerStats {
  role: PlayerRoleEnum | null;
}

export const usePlayerStats = ({ role }: IUsePlayerStats) => {
  const { t } = useTranslation();

  const goalieStatsSortingOptions = [
    { label: t(tKeys.gamesPlayed), value: 'games_played' },
    { label: t(tKeys.wins), value: 'wins' },
    { label: t(tKeys.shutouts), value: 'shout_outs' },
    { label: t(tKeys.gaa), value: 'goals_scored_against_avg' },
    { label: t(tKeys.savePercent), value: 'save_pct' },
  ];

  const skaterStatsSortingOptions = [
    { label: t(tKeys.gamesPlayed), value: 'games_played' },
    { label: t(tKeys.goals), value: 'goals' },
    { label: t(tKeys.assists), value: 'assists' },
    { label: t(tKeys.points), value: 'points' },
    { label: '+/-', value: 'plus_minus' },
  ];

  return {
    statsOptions:
      role === PlayerRoleEnum.skater
        ? skaterStatsSortingOptions
        : goalieStatsSortingOptions,
  };
};
