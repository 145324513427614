const config = window.config;

if (!config) {
  window.config = {
    AUTH_APP_CODE: process.env.REACT_APP_AUTH_APP_CODE,
    AUTH_URL: process.env.REACT_APP_AUTH_URL,
    REACT_APP_GAMEZONE_URL: process.env.REACT_APP_GAMEZONE_URL,
    REACT_APP_API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
    REACT_APP_OPEN_DATE: process.env.REACT_APP_OPEN_DATE,
    REACT_APP_CLOSE_DATE: process.env.REACT_APP_CLOSE_DATE,
    REACT_APP_FEATURE_NON_LOGIN_ENABLED:
      process.env.REACT_APP_FEATURE_NON_LOGIN_ENABLED,
    REACT_APP_PLAYER_BG_BASE_URL: process.env.REACT_APP_PLAYER_BG_BASE_URL,
    SHARE_URL: process.env.REACT_APP_SHARE_URL,
    REACT_APP_GTM_TOKEN: process.env.REACT_APP_GTM_TOKEN,
    REACT_APP_IS_GTAG_ENABLED: process.env.REACT_APP_IS_GTAG_ENABLED,
    REACT_APP_TOP_PRESELECTED: process.env.REACT_APP_TOP_PRESELECTED,
    REACT_APP_TESTERS_IDS: process.env.REACT_APP_TESTERS_IDS,
  };
}

export const { AUTH_APP_CODE, AUTH_URL, SHARE_URL } = window.config;

export const GAMEZONE_URL = window.config.REACT_APP_GAMEZONE_URL as string;

export const API_BASE_URL = window.config.REACT_APP_API_BASE_URL as string;

export const OPEN_DATE = window.config.REACT_APP_OPEN_DATE as string;

export const CLOSE_DATE = window.config.REACT_APP_CLOSE_DATE as string;
export const FEATURE_NON_LOGIN_ENABLED =
  window.config.REACT_APP_FEATURE_NON_LOGIN_ENABLED === 'true';

export const PLAYER_BG_BASE_URL = window.config
  .REACT_APP_PLAYER_BG_BASE_URL as string;

export const GTM_TOKEN = window.config.REACT_APP_GTM_TOKEN as string;
export const IS_GTAG_ENABLED =
  window.config.REACT_APP_IS_GTAG_ENABLED === 'true';

export const TOP_PRESELECTED =
  window.config.REACT_APP_TOP_PRESELECTED?.split(',')?.map(Number) || [];

export const TESTERS_IDS =
  window.config.REACT_APP_TESTERS_IDS?.split(',')?.map(Number) || [];

export const UI_SETTINGS = {
  MOBILE: {
    PX: '10px',
  },
  DESKTOP: {
    PX: '16px',
  },
};
