import { FC } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import { ReactComponent as CloseIcon } from '@/assets/icons/close-rounded.svg';

import { GreyButton } from '@/elements/Button';

interface IModalAlert {
  isOpen?: boolean;
  onClose: () => void;
  message?: string | React.ReactNode;
}

export const ModalAlert: FC<IModalAlert> = ({
  isOpen = true,
  onClose,
  message,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isCentered {...{ isOpen, onClose }}>
      <ModalOverlay backdropFilter='' bg='rgba(0, 4, 36, 0.75)' />
      <ModalContent bg='#F23451' maxW='800px' w='100%'>
        <Button
          _hover={{
            bg: 'var(--chakra-colors-blackAlpha-100)',
          }}
          h='40px'
          onClick={onClose}
          p='0'
          pos='absolute'
          right='1rem'
          size='lg'
          top='1rem'
          w='40px'
        >
          <CloseIcon />
        </Button>
        <ModalBody p='88px 48px'>
          <Text
            color='#000'
            fontSize='26px'
            fontWeight='400'
            lineHeight='1.4'
            mb='34px'
            textAlign='center'
          >
            {message}
          </Text>

          <GreyButton
            borderRadius='10px'
            fontSize='14px'
            fontWeight='400'
            h='auto'
            letterSpacing='1.122px'
            lineHeight='1.3'
            maxW='204px'
            onClick={onClose}
            p='18px'
            textTransform='uppercase'
            w='100%'
          >
            {t(tKeys.gotIt)}
          </GreyButton>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
