import { FC, useCallback, useState, useRef } from 'react';

import {
  Flex,
  LinkBox,
  LinkOverlay,
  Icon,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';
import { Link as ReactRouterLink } from 'react-router-dom';

import { IHeaderNavigation } from '@/hooks/useAppNav';

import NavDropdown from './NavDropdown';

import { FONT_SIZE } from '.';

const NavItem: FC<IHeaderNavigation> = ({
  label,
  linkData,
  selected,
  subLinks,
  renderIcon,
  withDropdown,
}) => {
  const [open, setOpen] = useState(false);
  const navRef = useRef(null);

  const { to, isExternal } = { ...linkData };

  const handleOutsideClick = useCallback(() => setOpen(false), []);

  useOutsideClick({
    ref: navRef,
    handler: handleOutsideClick,
  });

  const linkProps = !subLinks && {
    ...(!isExternal && { as: ReactRouterLink }),
    [isExternal ? 'href' : 'to']: to,
    isExternal,
  };

  const dropdownIcon = (
    <Icon
      as={open ? FaChevronUp : FaChevronDown}
      h='20px'
      transform={open ? '' : 'translateY(-2px)'}
      w='20px'
    />
  );

  const switchDropdown = () => {
    setOpen((isOpen) => !isOpen);
  };

  return (
    <LinkBox
      align='center'
      as={Flex}
      bg={selected ? '#00A9E0' : 'white'}
      borderRadius='8px 8px 0 0'
      color={selected ? 'white' : 'NHLNavy'}
      cursor='pointer'
      onClick={switchDropdown}
      p='12px 16px'
      position='relative'
      ref={navRef}
    >
      <LinkOverlay {...linkProps} />
      {withDropdown
        ? dropdownIcon
        : renderIcon?.({
            fill: selected ? 'white' : '#00053E',
          })}
      <Text
        fontSize={FONT_SIZE}
        fontWeight='700'
        ml='10px'
        textTransform='capitalize'
      >
        {label}
      </Text>

      {subLinks && <NavDropdown isOpen={open} {...{ subLinks }} />}
    </LinkBox>
  );
};

export default NavItem;
