import { Flex } from '@chakra-ui/react';

import LangSwitcher from '@/components/LangSwitcher';

import { useAppNav } from '@/hooks/useAppNav';
import { useJWT } from '@/hooks/useJWT.hook';

import NavItem from './NavItem';

export const Navigation = () => {
  const { navigationLinks, logoutLink } = useAppNav();
  const { removeJWT } = useJWT();

  const logout = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    removeJWT();
  };

  return (
    <Flex alignItems='stretch' gap='4px' h='48px' justify='center' w='100%'>
      {navigationLinks.map((link, idx) => (
        <NavItem key={idx} {...link} />
      ))}

      <LangSwitcher />

      {/* Logout button */}
      <Flex onClick={logout}>
        <NavItem {...logoutLink} />
      </Flex>
    </Flex>
  );
};

export const FONT_SIZE = '13px';
