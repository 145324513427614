import { Global } from '@emotion/react';

import GothamBlack from '@/assets/fonts/Gotham-Black.woff2';
import GothamBold from '@/assets/fonts/Gotham-Bold.woff2';
import GothamLight from '@/assets/fonts/Gotham-Light.woff2';
import GothamMedium from '@/assets/fonts/Gotham-Medium.woff2';
import GothamThin from '@/assets/fonts/Gotham-Thin.woff2';

const Fonts = () => (
  <Global
    styles={`
        /* latin */
        @font-face {
            font-family: "Gotham";
            font-style: normal;
            font-weight: 100;
            src: url(${GothamThin}) format("woff2");
        }

        /* latin */
        @font-face {
            font-family: "Gotham";
            font-style: normal;
            font-weight: 300;
            src: url(${GothamLight}) format("woff2");
        }
        /* latin */
        @font-face {
            font-family: "Gotham";
            font-style: normal;
            font-weight: 400;
            src: url(${GothamMedium}) format("woff2");
        }
        /* latin */
        @font-face {
            font-family: "Gotham";
            font-style: normal;
            font-weight: 700;
            src: url(${GothamBold}) format("woff2");
        }
        /* latin */
        @font-face {
            font-family: "Gotham";
            font-style: normal;
            font-weight: 900;
            src: url(${GothamBlack}) format("woff2");
        }
        `}
  />
);

export default Fonts;
