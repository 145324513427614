import EST from '@/assets/icons/countries/EST.svg';
import FRN from '@/assets/icons/countries/FRN.png';
import GBR from '@/assets/icons/countries/GBR.svg';
import LAT from '@/assets/icons/countries/LAT.svg';
import RUS from '@/assets/icons/countries/RUS.png';
import SVK from '@/assets/icons/countries/SVK.svg';

/* eslint-disable import/extensions */
import cs from '@/i18n/locales/cs/translation.json';
import de from '@/i18n/locales/de/translation.json';
import en from '@/i18n/locales/en/translation.json';
import es from '@/i18n/locales/es/translation.json';
import fi from '@/i18n/locales/fi/translation.json';
import fr from '@/i18n/locales/fr/translation.json';
import sk from '@/i18n/locales/sk/translation.json';
import sv from '@/i18n/locales/sv/translation.json';

export const LANGUAGES = {
  cs: { label: 'CZE', title: 'Czech' },
  de: { label: 'DEU', title: 'German' },
  en: { label: 'ENG', title: 'English' },
  es: { label: 'ESP', title: 'Spanish' },
  fi: { label: 'FIN', title: 'Finnish' },
  fr: { label: 'FRA', title: 'French' },
  sk: { label: 'SVK', title: 'Slovak' },
  sv: { label: 'SWE', title: 'Swedish' },
} as const;

export const DEFAULT_LANGUAGE = en;

export type LanguageCodeType = keyof typeof LANGUAGES;
export type LanguageInfoType = (typeof LANGUAGES)[LanguageCodeType];
export type LanguageType = LanguageInfoType & { code: keyof typeof LANGUAGES };

// hardcoded enabled list of languages
export const enabledLanguagesCodes = Object.keys(
  LANGUAGES,
) as LanguageCodeType[];

export const enabledLanguages = Object.entries(LANGUAGES).reduce(
  (acc, [code, lang]) =>
    enabledLanguagesCodes.includes(code as LanguageCodeType)
      ? [...acc, { code, ...lang } as LanguageType]
      : acc,
  [] as LanguageType[],
);

export const resources = {
  cs: { translation: cs },
  de: { translation: de },
  en: { translation: en },
  es: { translation: es },
  fi: { translation: fi },
  fr: { translation: fr },
  sk: { translation: sk },
  sv: { translation: sv },
};

export type TFunc = (key: string) => string;
export type tKeysUnion = keyof typeof DEFAULT_LANGUAGE;
export type tKeysType = { [key in tKeysUnion]: tKeysUnion };

export const tKeys = Object.fromEntries(
  Object.keys(en).map((key) => [key, key]),
) as tKeysType;

export const getLanguageByCode = (code: LanguageCodeType): LanguageType =>
  enabledLanguages.find((lang) => lang.code === code) || enabledLanguages[0];

export const countryFlagByCode = (code: LanguageCodeType) => {
  switch (code.toLowerCase()) {
    case 'es':
      return EST;
    case 'lt':
      return LAT;
    case 'sl':
      return SVK;
    case 'rus':
      return RUS;
    case 'fr':
      return FRN;
    case 'en':
    default:
      return GBR;
  }
};
