import { FC, SVGProps } from 'react';

export const HeartHandshakeIcon: FC<SVGProps<any>> = ({ fill = 'white' }) => {
  return (
    <svg
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.4201 4.58045C19.9184 4.07702 19.3223 3.67758 18.6659 3.40503C18.0095 3.13248 17.3058 2.99219 16.5951 2.99219C15.8844 2.99219 15.1806 3.13248 14.5243 3.40503C13.8679 3.67758 13.2718 4.07702 12.7701 4.58045L12.0001 5.36045L11.2301 4.58045C10.7284 4.07702 10.1323 3.67758 9.47591 3.40503C8.81953 3.13248 8.1158 2.99219 7.40509 2.99219C6.69437 2.99219 5.99065 3.13248 5.33427 3.40503C4.67789 3.67758 4.08176 4.07702 3.58009 4.58045C1.46009 6.70045 1.33009 10.2804 4.00009 13.0004L12.0001 21.0004L20.0001 13.0004C22.6701 10.2804 22.5401 6.70045 20.4201 4.58045Z'
        stroke={fill}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
      <path
        d='M12.0017 5.35938L8.87166 8.49937C8.47595 8.89832 8.25391 9.43746 8.25391 9.99937C8.25391 10.5613 8.47595 11.1004 8.87166 11.4994V11.4994C9.2706 11.8951 9.80975 12.1171 10.3717 12.1171C10.9336 12.1171 11.4727 11.8951 11.8717 11.4994L14.1317 9.28937C14.6933 8.73366 15.4515 8.42196 16.2417 8.42196C17.0318 8.42196 17.79 8.73366 18.3517 9.28937L20.7517 11.6894'
        stroke={fill}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
      <path
        d='M18 15L16 13'
        stroke={fill}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
      <path
        d='M15 18L13 16'
        stroke={fill}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </svg>
  );
};
