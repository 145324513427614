import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { tKeys } from '@/i18n';

import { PlayerRoleEnum } from '@/enums/PlayerRole.enum';
import { RoutesEnum } from '@/enums/routes.enum';

import { createPath } from '@/routes/types';

import { useFiltersStore } from '@/store/hooks/useFiltersStore.hook';

export const useHeader = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { filters } = useFiltersStore();

  const isPageWithBackButton = useMemo(() => {
    return [RoutesEnum.submitted].includes(pathname as RoutesEnum);
  }, [pathname]);

  const isPageWithTitledHeader = useMemo(
    () =>
      [RoutesEnum.search, RoutesEnum.searchSkills].includes(
        pathname as RoutesEnum,
      ),
    [pathname],
  );

  const playerPath = useMemo(
    () =>
      createPath({
        path: RoutesEnum.player,
        params: { id },
      }),
    [id],
  );

  const isPageWithoutMobileHeader = useMemo(
    () => [playerPath, RoutesEnum.onboarding].includes(pathname),
    [pathname, playerPath],
  );

  const getPageTitle = useMemo(() => {
    if (
      pathname === RoutesEnum.search &&
      filters.role === PlayerRoleEnum.skater
    )
      return t(tKeys.selectSkaters);
    if (
      pathname === RoutesEnum.search &&
      filters.role === PlayerRoleEnum.goalie
    )
      return t(tKeys.selectGoalies);
    if (pathname === RoutesEnum.search) return t(tKeys.selectPlayer);

    return null;
  }, [filters.role, pathname, t]);

  const linkToBackButton = useMemo(() => {
    if (
      pathname.includes('player') ||
      [RoutesEnum.searchSkills].includes(pathname as RoutesEnum)
    ) {
      return -1;
    }

    return RoutesEnum.home;
  }, [pathname]) as RoutesEnum;

  return {
    getPageTitle,
    isPageWithTitledHeader,
    isPageWithBackButton,
    isPageWithoutMobileHeader,
    linkToBackButton,
  };
};
