import React from 'react';

import { RoutesEnum } from '@/enums/routes.enum';

export interface IRoutes {
  path: RoutesEnum | string;
  element: React.ReactNode;
  children?: IRoutes[];
}

type TArgsWithParams = { path: RoutesEnum; params: any };

export const createPath = (args: TArgsWithParams): string => {
  if (!args.hasOwnProperty('params')) return args.path;

  return Object.entries((args as TArgsWithParams).params).reduce(
    (previousValue: string, [param, value]) =>
      previousValue.replace(`:${param}`, '' + value),
    args.path,
  );
};
