const colors = {
  NHLYellow: '#FFB81C',
  NHLDarkYellow: '#BC9341',
  gold: '#B9975B',
  primaryYellow: '#FDB71A',
  NHLBlue: '#132C69',
  NHLNavy: '#00053E',
  NHLNavyHover: '#00548D',
  primaryBlue: '#0D2D6C',
  secondaryBlue: '#031A38',
  tertiaryBlue: '#011838',
  NHLRed: '#DA1A32',
  NHLDarkRed: '#6F2A32',
  secondaryRed: '#8B0D20',
  NHLSecondaryHover: '#0183DA',
  NHLGrey: '#E4E5E7',
  white: '#F7F7F7',
  absoluteWhite: '#FFFFFF',
  black: '#000000',
  secondaryBlack: '#111111',
  tertiaryBlack: '#121212',
  halfOpacityBlack: 'rgba(0, 0, 0, 0.50)',
  halfOpacityGray: 'rgba(255, 255, 255, 0.60)',

  success: '#0CD664',
  error: '#F23451',

  goldGradient:
    'linear-gradient(0deg, #DDCBA4 0%, #DDCBA4 100%), linear-gradient(139deg, #F9CF68 10.23%, #FDD26A 25.61%, #EDC462 51.14%, #D0A954 87.71%)',
  preSelectedGradient:
    'linear-gradient(180deg, rgba(118, 35, 47, 0) 35.15%, rgba(118, 35, 47, 0.4) 99.94%), #FFB81C',
};

export default colors;
