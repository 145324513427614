import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage.hook';

export const useLang = () => {
  const {
    value: lang,
    setItem: setLang,
    removeItem: removeLang,
  } = useLocalStorage<string>(StorageKeysEnum.i18nextLng);

  return { lang, setLang, removeLang };
};
