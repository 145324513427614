import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react';

import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { api } from '@/services/api.service';

import { API_BASE_URL, GAMEZONE_URL } from '@/utils/constants';

import { getStorageItem } from './localStorage';

export type ApiEndpointsNamesType = keyof typeof api.endpoints;

export const prepareHeaders = (
  headers: Headers,
  api: {
    getState: () => unknown;
    extra: unknown;
    endpoint: string;
    type: 'query' | 'mutation';
    forced?: boolean | undefined;
  },
) => {
  const jwt = getStorageItem<string>(StorageKeysEnum.jwt);

  if (jwt && (api.endpoint as ApiEndpointsNamesType) !== 'getAllPlayers') {
    headers.set('authorization', `Bearer ${jwt}`);
  }

  return headers;
};

const baseQuery = fetchBaseQuery({ baseUrl: API_BASE_URL, prepareHeaders });
export const baseQueryWithRedirect: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const lang = getStorageItem<string>(StorageKeysEnum.i18nextLng);
  const result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 401) {
    localStorage.clear();
    window.location.href = `${GAMEZONE_URL}?lang=${lang}`;
  }
  return result;
};
