import { FC, PropsWithChildren, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { PageLoader } from '@/elements/PageLoader';

import { useAuth } from '@/hooks/useAuth';
import { usePlayers } from '@/hooks/usePlayers.hook';
import { useVoteDetails } from '@/hooks/useVoteDetails.hook';

export const AppProvider: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();
  const { isLoading } = useAuth();
  const { isLoading: isPlayersLoading } = usePlayers();
  useVoteDetails(); //tmp solution for testing

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return (
    <>
      {children}
      {(isLoading || isPlayersLoading) && <PageLoader />}
    </>
  );
};
