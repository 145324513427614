import React, { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerOverlay,
  Image,
  useTheme,
  DrawerFooter,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { tKeys, enabledLanguagesCodes } from '@/i18n';

import LogoutIcon from '@/assets/icons/logout.svg';

import SideBarLangSwitcher from '@/components/LangSwitcher/SidebarLangSwitcher';
import LogoImage from '@/components/LogoImage';

import { useSidebarContext } from '@/contexts/sidebar.context';

import { BlackButton } from '@/elements/Button';

import { RoutesEnum } from '@/enums/routes.enum';

import { useAppNav } from '@/hooks/useAppNav';
import { useJWT } from '@/hooks/useJWT.hook';

import { SidebarItem } from './SidebarItem';

const Sidebar: React.FC = () => {
  const [openDropdown, setOpenDropdown] = useState<string>();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { onSidebarClose, isSidebarOpen } = useSidebarContext();
  const { jwt, removeJWT } = useJWT();
  const { linkToGZ, navigationLinks } = useAppNav();

  const goToHome = () => {
    onSidebarClose();
    navigate(RoutesEnum.home);
  };

  const handleLogout = () => {
    removeJWT();
    window.location.assign(linkToGZ);
  };

  const onOpenDropdown = (label: string) => setOpenDropdown(label);

  const isLangSwitcherEnabled = useMemo(
    () => enabledLanguagesCodes.length > 1,
    [],
  );

  useEffect(() => {
    if (!isSidebarOpen) setOpenDropdown('');
  }, [isSidebarOpen]);

  // in order to fix the issue, the drawer overlay doesn't hide
  if (!isSidebarOpen) return null;

  return (
    <Drawer isOpen={isSidebarOpen} onClose={onSidebarClose} placement='left'>
      <DrawerOverlay backdropFilter='blur(8px)' />
      <DrawerCloseButton size='lg' zIndex='1500' />
      <DrawerContent
        background='transparent'
        maxW='250px'
        sx={{
          '.chakra-modal__header:has(+ .chakra-modal__body > .sidebar-item:first-child.active)':
            {
              borderRadius: '0 0 16px 0',
            },
        }}
      >
        <DrawerHeader
          background={theme.colors.white}
          pt='25px'
          textAlign='center'
        >
          <LogoImage isClickable mx='auto' onClick={goToHome} w='117px' />
        </DrawerHeader>

        <DrawerBody
          display='flex'
          flexDirection='column'
          p='0'
          sx={{
            '&::before': {
              content: '""',
              height: '100%',
              bg: theme.colors.white,
              width: '30px',
              position: 'absolute',
            },
            '.sidebar-item:has(+ .active)': { borderRadius: '0 0 16px 0' },
            '.active + .sidebar-item, .active + .plug': {
              borderRadius: '0 16px 0 0',
            },
            '.active::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: '0',
              left: '0',
              width: '32px',
              height: '32px',
              borderTopLeftRadius: '16px',
              boxShadow: `0 -16px 0 0 ${theme.colors.white}`,
            },
            '.active::after': {
              content: '""',
              display: 'block',
              position: 'absolute',
              bottom: '0',
              left: '0',
              width: '32px',
              height: '32px',
              borderBottomLeftRadius: '16px',
              boxShadow: `0 16px 0 0 ${theme.colors.white}`,
            },
          }}
        >
          {navigationLinks.map((item, idx) => (
            <SidebarItem
              {...item}
              {...{ onOpenDropdown, openDropdown }}
              key={idx}
            />
          ))}

          {isLangSwitcherEnabled && (
            <SideBarLangSwitcher {...{ onOpenDropdown, openDropdown }} />
          )}

          <Box bg={theme.colors.white} className='plug' flex='1 1 0' />
        </DrawerBody>

        <DrawerFooter
          alignItems='center'
          background={theme.colors.white}
          flexDirection='column'
          gap='24px'
        >
          {jwt && (
            <BlackButton
              fontSize='12px'
              fontWeight='700'
              gap='8px'
              h='44px'
              leftIcon={<Image alt='' src={LogoutIcon} />}
              onClick={handleLogout}
              px='16px'
              variant='outline'
              // w='113px'
            >
              {t(tKeys.signOut)}
            </BlackButton>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default Sidebar;
