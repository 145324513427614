import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { GTM_TOKEN, IS_GTAG_ENABLED } from '@/utils/constants';

export const useGTMPageViewTrack = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (IS_GTAG_ENABLED && GTM_TOKEN) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'page_view',
      });
    }
  }, [pathname]);
};
