import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isProfileModalOpen: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    SET_IS_PROFILE_MODAL_OPEN(state, action) {
      state.isProfileModalOpen = action.payload;
    },
  },
});

export default appSlice.reducer;
export const { SET_IS_PROFILE_MODAL_OPEN } = appSlice.actions;
