import { PlayerPositionEnum } from '@/enums/PlayerPosition.enum';

export const getPositionAbbr = (
  position: PlayerPositionEnum,
  lang?: string,
) => {
  const POS_ABBR = {
    [PlayerPositionEnum.Center]: 'C',
    [PlayerPositionEnum.Defenseman]: 'D',
    [PlayerPositionEnum.Goalie]: 'G',
    [PlayerPositionEnum.LeftWing]: lang === 'fr' ? 'AG' : 'LW',
    [PlayerPositionEnum.RightWing]: lang === 'fr' ? 'AD' : 'RW',
  };
  return POS_ABBR[position] || '';
};

export const getUniqueValues = (arr: any[]): any[] => Array.from(new Set(arr));

export const checkIsValue = (val: any): boolean =>
  val !== undefined && val !== null && val !== 'undefined';

export const checkStatValue = (val: any) => (checkIsValue(val) ? val : '--');

export const getAge = (dob?: string) => {
  if (!dob) return { born: '--', age: '--' };

  const date = new Date(dob);
  const now = new Date();
  const born = date.getFullYear();
  const age = now.getFullYear() - date.getFullYear();

  return { born, age };
};

export const getNumberEnding = (num: number, lang: string = 'en'): string => {
  // TODO implementation different ending due to language
  if (lang !== 'en') {
    return String(num);
  }

  // Case for EN
  if (num <= 0) {
    return 'Invalid number';
  }

  const lastDigit = num % 10;
  const lastTwoDigits = num % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return num + 'th';
  }

  switch (lastDigit) {
    case 1:
      return num + 'st';
    case 2:
      return num + 'nd';
    case 3:
      return num + 'rd';
    default:
      return num + 'th';
  }
  // Case for EN
};
