import React, { FC, useCallback } from 'react';

import {
  Flex,
  Image,
  Text,
  useTheme,
  useToast as useChakraToast,
  UseToastOptions,
} from '@chakra-ui/react';

import CircleCheck from '@/assets/icons/circle-check.svg';
import CircleCross from '@/assets/icons/circle-cross.svg';

import { ModalAlert } from '@/components/Modals/ModalAlert';

import { useSettings } from './useSettings.hook';

export interface CustomToastComponentProps {
  message: string | React.ReactNode;
  type: 'success' | 'error';
}

export type CustomToastType = (
  message: string | React.ReactNode,
  type: 'success' | 'error',
  params?: UseToastOptions & {
    isModalOnDesktop?: boolean;
  },
) => void;

export type UseToastType = () => CustomToastType;

interface RenderProps {
  onClose: () => void;
}

const CustomToastComponent: FC<CustomToastComponentProps> = ({
  message,
  type,
}) => {
  const theme = useTheme();
  return (
    <Flex
      alignItems='center'
      bg={type === 'success' ? theme.colors.success : theme.colors.error}
      borderRadius='0px 0px 8px 8px'
      color={theme.colors.black}
      gap='9px'
      justifyContent='center'
      p='12px 0'
    >
      <Image
        alt=''
        h='20px'
        src={type === 'success' ? CircleCheck : CircleCross}
        w='20px'
      />
      <Text fontSize='12px' fontWeight='500' lineHeight='100%'>
        {message}
      </Text>
    </Flex>
  );
};

export const useToast: UseToastType = () => {
  const { isDesktop } = useSettings();
  const toast = useChakraToast();

  const customToast: CustomToastType = useCallback(
    (message, type, params) => {
      const isUseModal = params?.isModalOnDesktop && isDesktop;

      const renderToast = (props: RenderProps) =>
        isUseModal ? (
          <ModalAlert {...{ message, ...props }} />
        ) : (
          <CustomToastComponent {...{ message, type }} />
        );

      toast({
        containerStyle: { margin: 0 },
        duration: !isUseModal ? 3000 : null,
        isClosable: false,
        position: 'top',
        render: renderToast,
        ...params,
      });
    },
    [isDesktop, toast],
  );

  return customToast;
};
