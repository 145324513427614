import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { api } from '@/services/api.service';

import playersSlice from '@/store/slices/players.slice';
import userSlice from '@/store/slices/user.slice';

import appSlice from './slices/app.slice';
import filtersSlice from './slices/filters.slice';

const rootReducer = combineReducers({
  app: appSlice,
  filters: filtersSlice,
  players: playersSlice,
  user: userSlice,
  [api.reducerPath]: api.reducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
