import { TeamFilterOptionType } from '@/components/Search/FiltersBlock/FilterByTeam';

import { PlayerRoleEnum } from '@/enums/PlayerRole.enum';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  SET_ELIGIBLE_ONLY,
  SET_ROLE,
  SET_SEARCH_INPUT_VALUE,
  SET_SORTING,
  SET_TEAM_ID,
  SET_VOTE_ROLE_FILTER,
} from '@/store/slices/filters.slice';

export const useFiltersStore = () => {
  const filters = useAppSelector((store) => store.filters);
  const dispatch = useAppDispatch();

  const setRole = (role: PlayerRoleEnum) => dispatch(SET_ROLE(role));
  const setFilterByTeam = (team: TeamFilterOptionType | null) =>
    dispatch(SET_TEAM_ID(team ? team.id : null));
  const setEligibleOnly = (value: boolean) =>
    dispatch(SET_ELIGIBLE_ONLY(value));
  const setSorting = (param: string | null) =>
    dispatch(SET_SORTING(param || null));
  const setSearchInputValue = (text: string) =>
    dispatch(SET_SEARCH_INPUT_VALUE(text));
  const setVoteRoleFilter = (text: string) =>
    dispatch(SET_VOTE_ROLE_FILTER(text));

  return {
    filters,
    setRole,
    setFilterByTeam,
    setEligibleOnly,
    setSearchInputValue,
    setSorting,
    setVoteRoleFilter,
  };
};
