import { ButtonProps, Button as ChButton } from '@chakra-ui/react';

const BaseSolidButton = (props: ButtonProps) => (
  <ChButton textTransform='capitalize' variant='solid' {...props} />
);

// TODO need to improve hover and active states
const BlueButton = (props: ButtonProps) => {
  return (
    <BaseSolidButton
      _hover={{ bgColor: '#01B4EF' }}
      bgColor='#00A9E0'
      shadow='0px 4px 0px 0px #007298'
      {...props}
    />
  );
};

// TODO need to improve hover and active states
const GreyButton = (props: ButtonProps) => {
  return (
    <BaseSolidButton
      _hover={{ bgColor: '#808283' }}
      bgColor='NHLGrey'
      shadow='0px 4px 0px 0px #979899'
      {...props}
    />
  );
};

// TODO need to improve hover and active states
const YellowButton = (props: ButtonProps) => {
  return (
    <BaseSolidButton
      _hover={{ bgColor: '#A97914' }}
      bgColor='NHLYellow'
      shadow='0px 4px 0px 0px #BD8917'
      {...props}
    />
  );
};

// TODO need to improve hover and active states
const RedButton = (props: ButtonProps) => {
  return (
    <BaseSolidButton
      _hover={{ bgColor: '#A4192E' }}
      bgColor='#F23451'
      shadow='0px 4px 0px 0px #A4192E'
      textColor='white'
      {...props}
    />
  );
};

// TODO need to improve hover and active states
const TextButton = (props: ButtonProps) => {
  return <ChButton textTransform='capitalize' {...props} />;
};

// TODO need to improve hover and active states
const OutlinedButton = (props: ButtonProps) => {
  return <ChButton textTransform='capitalize' variant='outline' {...props} />;
};

// TODO need to improve hover and active states
const BlackButton = (props: ButtonProps) => {
  return (
    <BaseSolidButton
      _hover={{ bgColor: 'black' }}
      background='black'
      borderRadius='6px'
      {...props}
    />
  );
};

export {
  BlueButton,
  GreyButton,
  YellowButton,
  RedButton,
  TextButton,
  OutlinedButton,
  BlackButton,
};
