import { PlayerType } from '@/types/domain';

import { getUniqueValues } from '.';

export const getTeamList = (players: PlayerType[]) => {
  const teamIds = getUniqueValues(players.map((player) => player.team_id));
  return teamIds
    .map((id) => {
      const player = players.find(({ team_id }) => team_id === id);
      return {
        id: player?.team_id || '',
        logo: player?.team_logo || '',
        name: player?.team_name || '',
      };
    })
    .sort((a, b) => (a.name > b.name ? 1 : -1));
};
