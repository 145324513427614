import React from 'react';

import { Box, Button, Grid, Heading, Image, useTheme } from '@chakra-ui/react';
import { FiArrowLeftCircle } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';

import Burger from '@/assets/icons/burger.svg';
import CircleHelp from '@/assets/icons/circle-help.svg';
import HeaderBg from '@/assets/images/mobile_header_bg.png';

import LogoImage from '@/components/LogoImage';

import { useSidebarContext } from '@/contexts/sidebar.context';

import { RoutesEnum } from '@/enums/routes.enum';

import { useHeader } from '@/hooks/useHeader.hook';

export const MobileHeader: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { onSidebarOpen } = useSidebarContext();
  const {
    isPageWithBackButton,
    isPageWithTitledHeader,
    getPageTitle,
    isPageWithoutMobileHeader,
    linkToBackButton,
  } = useHeader();
  const goToHome = () => {
    navigate(RoutesEnum.home);
  };

  const renderRightColumn = () => {
    if (isPageWithTitledHeader) {
      return (
        <Box textAlign='right' w='100%'>
          <Button
            h='auto'
            mx='auto'
            onClick={goToHome}
            p='0'
            variant='unstyled'
          >
            <LogoImage isClickable width='77px' />
          </Button>
        </Box>
      );
    }
    if (isPageWithBackButton) return null;
    return (
      <Button
        h='24px'
        ml='auto'
        onClick={() => navigate(RoutesEnum.onboarding)}
        variant='unstyled'
        w='24px'
      >
        <Image alt='' src={CircleHelp} />
      </Button>
    );
  };
  return isPageWithoutMobileHeader ? null : (
    <Box
      as='header'
      backgroundColor={theme.colors.primaryBlue}
      backgroundImage={`url(${HeaderBg})`}
      backgroundPosition='center'
      backgroundRepeat='no-repeat'
      backgroundSize='cover'
      boxShadow={'0px 4px 4px 0px rgba(0, 0, 0, 0.25)'}
      display='block'
      h='84px'
      position='fixed'
      px='20px'
      py='0'
      top='0'
      w='100%'
      zIndex='997'
    >
      <Grid
        alignItems='center'
        h='100%'
        templateColumns='1fr minmax(132px, 2fr) 1fr'
        w='100%'
      >
        {isPageWithBackButton || isPageWithTitledHeader ? (
          <Link to={linkToBackButton}>
            <Image
              alt='menu-icon'
              as={FiArrowLeftCircle}
              cursor='pointer'
              h='25px'
              w='25px'
            />
          </Link>
        ) : (
          <Image
            alt='menu-icon'
            cursor='pointer'
            onClick={onSidebarOpen}
            src={Burger}
          />
        )}

        {isPageWithTitledHeader ? (
          <Heading
            as='h1'
            fontSize='18px'
            fontWeight='700'
            gridColumn={2}
            textAlign='center'
          >
            {getPageTitle}
          </Heading>
        ) : (
          <Button
            gridColumn={2}
            h='auto'
            mx='auto'
            onClick={goToHome}
            p='0'
            variant='unstyled'
          >
            <LogoImage isClickable width='132px' />
          </Button>
        )}

        {renderRightColumn()}
      </Grid>
    </Box>
  );
};
