import { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { SearchParamsEnum } from '@/enums/searchParams.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useAppNav } from '@/hooks/useAppNav';
import { useJWT } from '@/hooks/useJWT.hook';
import { useLang } from '@/hooks/useLang.hook';
import { useLocalStorage } from '@/hooks/useLocalStorage.hook';

import { useGenerateJWTTokenMutation } from '@/services/api.service';

import { FEATURE_NON_LOGIN_ENABLED } from '@/utils/constants';

export const useAuth = () => {
  const { linkToGZ } = useAppNav();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { i18n } = useTranslation();
  const { jwt, setJWT, removeJWT } = useJWT();
  const { setLang } = useLang();
  const { setItem: setFavouriteTeamId } = useLocalStorage<number>(
    StorageKeysEnum.favourite_team_league_id,
  );

  const token = useMemo(
    () => searchParams.get(SearchParamsEnum.token),
    [searchParams],
  );
  const lang = useMemo(
    () => searchParams.get(SearchParamsEnum.lang),
    [searchParams],
  );

  const [triggerLogin, { isLoading }] = useGenerateJWTTokenMutation();

  useEffect(() => {
    if (!FEATURE_NON_LOGIN_ENABLED && !token && !jwt) {
      window.location.assign(linkToGZ);
    }
  }, [jwt, token, linkToGZ]);

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang, () => setLang(lang));
      searchParams.delete(SearchParamsEnum.lang);
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line
  }, [i18n, lang, setLang]);

  useEffect(() => {
    const handleLogin = async () => {
      try {
        const res = await triggerLogin({ token: token as string }).unwrap();
        setJWT(res.token);
        setFavouriteTeamId(res.favourite_team_league_id);
        searchParams.delete(SearchParamsEnum.token);
        setSearchParams(searchParams);
      } catch (e) {}
    };

    if (token) {
      removeJWT();
      handleLogin();
    }
    // eslint-disable-next-line
  }, [token]);

  return { isLoading };
};
