import React, { useEffect, useMemo, useState } from 'react';

import { Flex, Image, useTheme } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import HeaderBg from '@/assets/images/desktop_header_bg.png';

import LogoImage from '@/components/LogoImage';

import { RoutesEnum } from '@/enums/routes.enum';

import { useLang } from '@/hooks/useLang.hook';
import { useVoteDetails } from '@/hooks/useVoteDetails.hook';

import { Navigation } from '../../Navigation';

export const DesktopHeader: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Flex
      align='center'
      as='header'
      background={`url(${HeaderBg}) center / cover no-repeat`}
      backgroundColor={theme.colors.primaryBlue}
      direction='column'
      h='190px'
      justify='space-between'
      position='relative'
      px='20px'
      py='0'
      w='100%'
      zIndex='997'
    >
      <Flex direction='column' h='100%' justify='center'>
        <LogoImage height='90px' isClickable />
      </Flex>

      <Navigation />
    </Flex>
  );
};
