import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';

import {
  Flex,
  FlexProps,
  Grid,
  Text,
  useOutsideClick,
  useTheme,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  LanguageType,
  getLanguageByCode,
  LanguageCodeType,
  enabledLanguages,
} from '@/i18n';

import { ReactComponent as GlobeIcon } from '@/assets/icons/globe.svg';

import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage.hook';

import { FONT_SIZE } from '../Layout/Navigation';

type LangItemProps = FlexProps &
  LanguageType & {
    isActive?: boolean;
  };

const LangItem: React.FC<LangItemProps> = ({ label, isActive, onClick }) => {
  return (
    <Flex
      _hover={{ bg: '#DA1A32', color: '#FFFFFF' }}
      align='center'
      bg={isActive ? '#DA1A32' : ''}
      color={isActive ? '#FFFFFF' : '#262626'}
      cursor='pointer'
      fontSize='14px'
      fontWeight='700'
      justify='center'
      lineHeight='1.4'
      w='100%'
      {...{ onClick }}
    >
      {label}
    </Flex>
  );
};

const LangSwitcher: React.FC = () => {
  const theme = useTheme();
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const { value: storageLang, setItem: setLang } = useLocalStorage<string>(
    StorageKeysEnum.i18nextLng,
  );

  const dropdownRef = useRef(null);

  useOutsideClick({
    ref: dropdownRef,
    handler: () => setOpen(false),
  });

  const defaultLanguage = useMemo(
    () => getLanguageByCode(storageLang as LanguageCodeType),
    [storageLang],
  );

  const [currentLang, setCurrentLang] = useState(defaultLanguage);

  useEffect(() => setCurrentLang(defaultLanguage), [defaultLanguage]);

  const onLangChange = useCallback(
    (lang: LanguageType) => () => {
      setCurrentLang(lang);
      i18n.changeLanguage(lang.code, () => setLang(lang.code));
    },
    [i18n, setLang],
  );

  useEffect(() => setCurrentLang(defaultLanguage), [defaultLanguage]);

  const switchDropdown = () => {
    setOpen((isOpen) => !isOpen);
  };

  return (
    <Flex
      align='center'
      bg={theme.colors.white}
      borderRadius='8px 8px 0 0'
      color='NHLNavy'
      cursor='pointer'
      fontSize={FONT_SIZE}
      onClick={switchDropdown}
      p='16px 24px'
      position='relative'
      ref={dropdownRef}
    >
      <GlobeIcon />
      <Text fontWeight='700' ml='10px' textTransform='capitalize'>
        {currentLang.title}
      </Text>

      {open && (
        <Grid
          autoFlow='column'
          backdropFilter='blur(4px)'
          bg='rgba(255, 255, 255, 0.85)'
          borderRadius='0 0 8px 8px'
          boxShadow='0px 4px 16px 0px rgba(255, 255, 255, 0.25)'
          display={open ? 'grid' : 'none'}
          gridTemplate='repeat(4, 52px) / 1fr 1fr'
          overflow='hidden'
          position='absolute'
          right='0'
          top='100%'
          w='200%'
        >
          {enabledLanguages?.map((lang, idx) => (
            <LangItem
              {...lang}
              isActive={lang.code === storageLang}
              key={idx}
              onClick={onLangChange(lang)}
            />
          ))}
        </Grid>
      )}
    </Flex>
  );
};

export default LangSwitcher;
