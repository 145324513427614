import { createApi } from '@reduxjs/toolkit/dist/query/react';
import dayjs from 'dayjs';

import { ApiTagsEnum } from '@/enums/apiTags.enum';

import {
  AllPlayersRes,
  LoginReqBody,
  LoginResponse,
  MakeVotesReqBody,
  MakeVotesRes,
  VoteDetailsRes,
} from '@/types/domain';

import { baseQueryWithRedirect } from '@/utils/api';
import { AUTH_APP_CODE, AUTH_URL } from '@/utils/constants';

export const api = createApi({
  reducerPath: 'mainApi',
  tagTypes: Object.values(ApiTagsEnum),
  baseQuery: baseQueryWithRedirect,
  endpoints: (builder) => ({
    generateJWTToken: builder.mutation<LoginResponse, LoginReqBody>({
      query: (body) => ({
        url: '/login',
        method: 'POST',
        body,
      }),
    }),
    getAllPlayers: builder.query<AllPlayersRes, void>({
      query: () =>
        `https://all-star-vote-dev.azureedge.net/nhl-allstar-players/players.json?date=${dayjs().format(
          'YYYY-MM-DDTHH',
        )}`,
      providesTags: [ApiTagsEnum.GET_PLAYERS],
    }),
    makeVotes: builder.mutation<MakeVotesRes, MakeVotesReqBody>({
      query: ({ votes }) => ({
        url: '/skills-vote',
        method: 'POST',
        body: { votes },
      }),
      invalidatesTags: [ApiTagsEnum.GET_VOTE_DETAILS],
    }),
    postVotes: builder.mutation<
      MakeVotesRes,
      {
        votes: Array<
          ({
            playerId: number;
          } | null)[]
        >;
      }
    >({
      query: ({ votes }) => ({
        url: '/skills-votes',
        method: 'POST',
        body: { votes },
      }),
    }),
    getVoteDetails: builder.query<VoteDetailsRes, void>({
      query: () => '/skills-vote-details',
      providesTags: [ApiTagsEnum.GET_VOTE_DETAILS],
    }),
    getSso: builder.query<{ redirectUrl: string }, void>({
      query: () => ({
        url: `${AUTH_URL}/sso/nhl?appCode=${AUTH_APP_CODE}`,
      }),
    }),
  }),
});

export const {
  useGenerateJWTTokenMutation,
  useMakeVotesMutation,
  useGetVoteDetailsQuery,
  useLazyGetSsoQuery,
  usePostVotesMutation,
} = api;
