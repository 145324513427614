import React, { useCallback, useMemo, useState, useEffect } from 'react';

import { Box, Flex, FlexProps, Text, useTheme } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  enabledLanguages,
  getLanguageByCode,
  LanguageCodeType,
  LanguageType,
} from '@/i18n';

import { ReactComponent as GlobeIcon } from '@/assets/icons/globe.svg';

import { FONT_SIZE } from '@/components/Layout/Navigation';

import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage.hook';

type LangItemProps = FlexProps &
  LanguageType & {
    isActive?: boolean;
  };

const LangItem: React.FC<LangItemProps> = ({ label, isActive, ...props }) => (
  <Flex
    align='center'
    bg={isActive ? '#DA1A32' : '#E4E5E7'}
    borderRadius='50%'
    color='#262626'
    cursor='pointer'
    fontSize='10px'
    h='30px'
    justify='center'
    w='30px'
    {...props}
  >
    {label}
  </Flex>
);

interface SidebarLangSwitcherProps {
  openDropdown?: string;
  onOpenDropdown?: (label: string) => void;
}

const SidebarLangSwitcher: React.FC<SidebarLangSwitcherProps> = ({
  onOpenDropdown,
  openDropdown,
}) => {
  const { i18n } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { value: storageLang, setItem: setLang } = useLocalStorage<string>(
    StorageKeysEnum.i18nextLng,
  );

  const defaultLanguage = useMemo(
    () => getLanguageByCode(storageLang as LanguageCodeType),
    [storageLang],
  );

  const [currentLang, setCurrentLang] = useState(defaultLanguage);

  const onLangChange = useCallback(
    (lang: LanguageType) => () => {
      setCurrentLang(lang);
      i18n.changeLanguage(lang.code, () => setLang(lang.code));
    },
    [i18n, setLang],
  );

  useEffect(() => setCurrentLang(defaultLanguage), [defaultLanguage]);

  const switchDropdown = () => {
    setOpen((isOpen) => !isOpen);
    onOpenDropdown?.(!open ? currentLang.label : '');
  };

  useEffect(() => {
    if (open && openDropdown !== currentLang.label) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDropdown]);

  return (
    <Box
      className={`sidebar-item ${open ? 'active' : ''}`}
      ml='30px'
      overflow='hidden'
      position='relative'
    >
      <Flex
        align='center'
        backgroundColor={open ? 'transparent' : theme.colors.white}
        color={open ? theme.colors.white : theme.colors.black}
        cursor='pointer'
        gap='16px'
        onClick={switchDropdown}
        p='16px'
        pl='24px'
        userSelect='none'
      >
        <GlobeIcon />
        <Text
          fontSize={FONT_SIZE}
          fontWeight='700'
          lineHeight='140%'
          textTransform='capitalize'
        >
          {currentLang.title}
        </Text>
      </Flex>
      {open && (
        <Flex align='center' gap='22px' pl='24px' py='16px' wrap='wrap'>
          {enabledLanguages?.map((lang, idx) => (
            <LangItem
              {...lang}
              isActive={lang.code === storageLang}
              key={idx}
              onClick={onLangChange(lang)}
            />
          ))}
        </Flex>
      )}
    </Box>
  );
};

export default SidebarLangSwitcher;
