import React, { SVGProps, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { NavLinkProps, useLocation, useParams } from 'react-router-dom';

import { tKeys } from '@/i18n';

import { ReactComponent as TicketIcon } from '@/assets/icons/ticket.svg';

import { RoutesEnum } from '@/enums/routes.enum';

import { useLang } from '@/hooks/useLang.hook';

import { HeartHandshakeIcon } from '@/icons/HeartHandshake.icon';
import { HomeIcon } from '@/icons/Home.icon';
import { LogoutIcon } from '@/icons/Logout.icon';
import { VoteIcon } from '@/icons/Vote.icon';

import { GAMEZONE_URL } from '@/utils/constants';

import { createPath } from '../routes/types';

interface LinkData extends NavLinkProps {
  isExternal?: boolean;
}

export interface IHeaderNavigation {
  label: string;
  selected?: boolean;
  linkData?: LinkData;
  renderIcon?: (props?: SVGProps<any>) => React.ReactNode;
  subLinks?: IHeaderNavigation[];
  withDropdown?: boolean;
}

export const useAppNav = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const { t } = useTranslation();
  const { lang } = useLang();

  const linkToGZ = useMemo(() => `${GAMEZONE_URL}?lang=${lang}`, [lang]);

  const navigationLinks = useMemo<IHeaderNavigation[]>(
    () => [
      {
        label: t(tKeys.home),
        renderIcon: (props) => <HomeIcon {...props} />,
        selected: pathname === RoutesEnum.home,
        linkData: {
          to: RoutesEnum.home,
        },
      },
      {
        label: t(tKeys.howToVote),
        renderIcon: (props) => <VoteIcon {...props} />,
        selected: pathname === RoutesEnum.onboarding,
        linkData: {
          to: RoutesEnum.onboarding,
        },
      },
      {
        label: t(tKeys.officialRules),
        renderIcon: (props) => <HeartHandshakeIcon {...props} />,
        selected: pathname === RoutesEnum.rules,
        linkData: {
          to: RoutesEnum.rules,
        },
      },
      {
        label: t(tKeys.nhlGameZone),
        renderIcon: (props) => <TicketIcon {...props} />,
        linkData: {
          to: linkToGZ,
          isExternal: true,
        },
      },
    ],
    [linkToGZ, pathname, t],
  );

  const logoutLink: IHeaderNavigation = {
    label: t(tKeys.logOut),
    renderIcon: (props) => <LogoutIcon {...props} />,
    linkData: {
      to: RoutesEnum.home,
    },
  };

  const isVotePage = useMemo(
    () =>
      pathname === RoutesEnum.home ||
      pathname ===
        createPath({ path: RoutesEnum.playerProfileDesktop, params: { id } }),
    [pathname, id],
  );
  const isPrePickedPlayersPage = useMemo(
    () =>
      pathname.includes(RoutesEnum.prePickedPlayers) ||
      pathname ===
        createPath({ path: RoutesEnum.prePickedPlayersWithId, params: { id } }),
    [pathname, id],
  );
  return {
    isPrePickedPlayersPage,
    isVotePage,
    navigationLinks,
    linkToGZ,
    logoutLink,
  };
};
