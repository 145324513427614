import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import SplashBg from '@/assets/images/splash_bg.png';

import LogoImage from '@/components/LogoImage';

import { RoutesEnum } from '@/enums/routes.enum';

import { useUserStore } from '@/store/hooks/useUserStore.hook';

import { OPEN_DATE, CLOSE_DATE, TESTERS_IDS } from '@/utils/constants';

interface SplashProps {
  isLoading: boolean;
}

const Splash: React.FC<SplashProps> = ({ isLoading }) => {
  return isLoading ? (
    <Flex
      alignItems='center'
      backgroundAttachment='fixed'
      backgroundImage={`url(${SplashBg})`}
      backgroundPosition='center'
      backgroundRepeat='no-repeat'
      backgroundSize='cover'
      height='100vh'
      justifyContent='center'
      left='0'
      position='fixed'
      top='0'
      width='100%'
      zIndex='99999'
    >
      <LogoImage w='250px' />
    </Flex>
  ) : null;
};

const withSplash = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  // eslint-disable-next-line react/display-name
  return (props: P) => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const timeoutRef = useRef<any>(null);
    const { user } = useUserStore();

    const isComing = dayjs(OPEN_DATE).isAfter();
    const isEnded = dayjs(CLOSE_DATE).isBefore();

    const handleClose = useCallback(() => {
      if (isEnded) {
        navigate(RoutesEnum.votingEnded);
        setIsLoading(false);
        return;
      }

      if (isComing && !TESTERS_IDS?.some((x) => x === user?.userId))
        navigate(RoutesEnum.coming);

      setIsLoading(false);
    }, [isComing, isEnded, navigate, user?.userId]);

    useEffect(() => {
      timeoutRef.current = setTimeout(handleClose, 2000);

      return () => {
        clearTimeout(timeoutRef.current);
      };
    }, [handleClose, isEnded, navigate]);

    return (
      <>
        <Splash isLoading={isLoading} />
        <WrappedComponent {...props} />
      </>
    );
  };
};

export default withSplash;
