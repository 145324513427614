import React, { FC, PropsWithChildren } from 'react';

import {
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router';

import { tKeys } from '@/i18n';

import bg from '@/assets/images/splash_bg.png';

import LogoImage from '@/components/LogoImage';

import { YellowButton } from '@/elements/Button';

import { RoutesEnum } from '@/enums/routes.enum';

import { useJWT } from '@/hooks/useJWT.hook';
import { useSettings } from '@/hooks/useSettings.hook';
import { useToast } from '@/hooks/useToast.hook';

import { useLazyGetSsoQuery } from '@/services/api.service';

const ACCESSIBLE_PAGES = [RoutesEnum.coming, RoutesEnum.votingEnded];

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isDesktop } = useSettings();
  const { jwt } = useJWT();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [trigger] = useLazyGetSsoQuery();

  const isAccessiblePage = ACCESSIBLE_PAGES.some((route) =>
    matchPath(pathname, route),
  );

  const loginHandler = async () => {
    try {
      const response = await trigger();
      if (response?.data?.redirectUrl) {
        window.location.assign(response.data.redirectUrl);
      }
    } catch (error) {
      console.error('loginHandler error:', error);
      toast(t(tKeys.somethingWentWrong), 'error');
    }
  };

  const clickHandler = (event: any) => {
    if (isAccessiblePage) return;

    event.preventDefault();
    event.stopPropagation();

    const targetId = event.target?.id;

    if (targetId === 'login-btn' || targetId === 'sign-up-btn') {
      return loginHandler();
    }
    onOpen();
  };

  if (jwt) return <>{children}</>;

  return (
    <div onClick={clickHandler}>
      {children}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bgImage={bg} bgSize='cover' maxW='800px' p='20px'>
          <LogoImage h='70px' />
          <ModalHeader>{t(tKeys.loginToVote)}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{t(tKeys.inOrderToSubmit)}</Text>
            <br />
            <Text>{t(tKeys.signUpBelow)}</Text>
          </ModalBody>
          <ModalFooter flexDirection={isDesktop ? 'row' : 'column'} gap='10px'>
            <YellowButton
              onClick={loginHandler}
              textTransform='uppercase'
              w='200px'
            >
              {t(tKeys.login)}
            </YellowButton>
            <YellowButton
              onClick={loginHandler}
              textTransform='uppercase'
              w='200px'
            >
              {t(tKeys.signUp)}
            </YellowButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
