export enum RoutesEnum {
  coming = '/coming-soon',
  home = '/',
  voteSkills = '/skills',
  searchSkills = '/skills/search',
  playerProfileDesktop = '/:id', // Player Profile on Desktop
  lockedIn = '/locked-in',
  myAllStars = '/my-all-stars',
  onboarding = '/how-to-vote',
  player = '/player/:id', // Player Profile on Mobile
  search = '/search',
  rules = '/rules',
  social = '/social',
  submitted = '/submitted',
  prePickedPlayers = '/pre-picked-players',
  prePickedPlayersWithId = '/pre-picked-players/:id', // PrePicked Player Profile on Desktop
  votingEnded = '/voting-ended',
}
