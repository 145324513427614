import React from 'react';

import { Box, Container } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import DesktopBg from '@/assets/images/desktop_bg.png';

import Sidebar from '@/components/Layout/Sidebar';

import { useHeader } from '@/hooks/useHeader.hook';
import { useSettings } from '@/hooks/useSettings.hook';

import { Footer } from './Footer';
import { DesktopHeader } from './Header/desktop';
import { MobileHeader } from './Header/mobile';

const Layout: React.FC = () => {
  const { isDesktop } = useSettings();
  const { isPageWithoutMobileHeader } = useHeader();

  if (isDesktop) {
    return (
      <>
        <DesktopHeader />

        <Box bg={`url(${DesktopBg}) #000424F2`}>
          <Box bg='#000424F2' py='40px'>
            <Container maxW='1200px'>
              <Outlet />
            </Container>
          </Box>
        </Box>

        <Footer />
      </>
    );
  }

  return (
    <>
      <MobileHeader />
      <Sidebar />

      {/*depends on header height*/}
      <Box pt={isPageWithoutMobileHeader ? '0' : '84px'}>
        <Outlet />
      </Box>

      <Footer />
    </>
  );
};

export default Layout;
