import React, { createContext, useEffect } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { useSettings } from '@/hooks/useSettings.hook';

type SidebarContextType = {
  onSidebarOpen(): void;
  onSidebarClose(): void;
  isSidebarOpen: boolean;
};

const SidebarContext = createContext<SidebarContextType | null>(null);

const SidebarContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { isDesktop } = useSettings();
  const { pathname } = useLocation();
  const {
    isOpen: isSidebarOpen,
    onOpen: onSidebarOpen,
    onClose: onSidebarClose,
  } = useDisclosure();

  useEffect(() => {
    if (isSidebarOpen) document.body.style.overflow = 'hidden';
    else if (!isSidebarOpen) document.body.style.overflow = 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    onSidebarClose();
  }, [onSidebarClose, pathname]);

  useEffect(() => {
    if (isSidebarOpen && isDesktop) onSidebarClose();
  }, [isDesktop, isSidebarOpen, onSidebarClose]);

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        onSidebarClose,
        onSidebarOpen,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

function useSidebarContext(): SidebarContextType {
  const context = React.useContext(SidebarContext);
  if (!context) {
    throw new Error(
      'useSidebarContext must be used within a SidebarContextProvider',
    );
  }
  return context;
}

export { SidebarContextProvider, useSidebarContext };
