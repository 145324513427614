import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserType } from '@/types/domain';

export interface UserSliceIStateType {
  user: UserType | null;
}
const initialState = {
  user: null,
} as UserSliceIStateType;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    SET_USER(state, action: PayloadAction<UserSliceIStateType['user']>) {
      state.user = action.payload;
    },
  },
});

export default userSlice.reducer;
export const { SET_USER } = userSlice.actions;
