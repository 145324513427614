import { FC } from 'react';

import {
  Flex,
  Text,
  Link as ChakraLink,
  LinkProps,
  useTheme,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { useSidebarContext } from '@/contexts/sidebar.context';

import { IHeaderNavigation } from '@/hooks/useAppNav';

import { FONT_SIZE } from '../Navigation';

interface SidebarLinkProps extends IHeaderNavigation, LinkProps {}

export const SidebarLink: FC<SidebarLinkProps> = ({
  label,
  linkData,
  renderIcon,
  selected,
  ...props
}) => {
  const theme = useTheme();
  const { onSidebarClose } = useSidebarContext();

  const { to, isExternal } = { ...linkData };

  const linkProps = {
    ...(!isExternal && { as: ReactRouterLink }),
    [isExternal ? 'href' : 'to']: to,
    isExternal,
  };

  return (
    <ChakraLink
      _hover={{ textDecoration: 'none' }}
      backgroundColor={selected ? 'transparent' : theme.colors.white}
      className={`sidebar-item ${selected ? 'active' : ''}`}
      ml='30px'
      onClick={onSidebarClose}
      position='relative'
      {...linkProps}
      {...props}
    >
      <Flex
        align='center'
        color={selected ? theme.colors.white : theme.colors.black}
        gap='16px'
        p='16px'
        pl='24px'
        w='100%'
      >
        {renderIcon?.({
          fill: selected ? theme.colors.white : theme.colors.black,
        })}
        <Text
          fontSize={FONT_SIZE}
          fontWeight='700'
          lineHeight='140%'
          textTransform='capitalize'
        >
          {label}
        </Text>
      </Flex>
    </ChakraLink>
  );
};
