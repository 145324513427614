import React, { createContext, FC, ReactNode, useState } from 'react';

import { toBlob, toPng } from 'html-to-image';

import { useSharingOptions } from '@/hooks/useSharingOptions';

import { usePlayersStore } from '@/store/hooks/usePlayersStore.hook';

import { createBallotImageUrl } from '@/utils/createBallotImageUrl';

type SharingContextType = {
  loading: boolean;
  setSharingRef: (el: HTMLElement) => void;
  onMobileShare: () => void;
  onDownloadImage: () => void;
};

const SharingContext = createContext<SharingContextType | null>(null);

const SharingContextProvider: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const { shareFileName, shareLink, shareTitle } = useSharingOptions();
  const [sharingElement, setSharingElement] = useState<HTMLElement | null>(
    null,
  );
  const [loading, setLoading] = useState<boolean>(false);

  const { selectedSkills } = usePlayersStore();

  const setSharingRef = (node: HTMLElement) => {
    if (node !== null) {
      setSharingElement(node);
    }
  };

  const onMobileShare = async () => {
    if (!sharingElement) return;

    setLoading(true);

    try {
      await toBlob(sharingElement);
      await toBlob(sharingElement);
      const imgBlob = await toBlob(sharingElement);

      if (!imgBlob) return;

      const shareFile = new File([imgBlob], 'asfv2024.png', {
        type: imgBlob.type,
        lastModified: Date.now(),
      });

      // const response = await fetch(imageUrl);
      // const imgBlob = await response.blob();
      // const shareFile = new File([imgBlob], 'asfv2024.png', {
      //   type: imgBlob.type,
      //   lastModified: Date.now(),
      // });

      await navigator
        ?.share?.({
          title: 'ASFV 2024',
          url: shareLink,
          text: shareTitle,
          files: [shareFile],
        })
        .catch(
          (e) => {}, //alert(`share error: ${e}`)
        );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onDownloadImage = async () => {
    if (!sharingElement) return;

    setLoading(true);

    const BROKEN_IMAGE_PLACEHOLDER =
      'data:image/gif;base64,R0lGODlhGQAZAPQAAISChKWipcbT7+fr/8bf51KuOdbf93vDc87b9/f3997n/2u2Y9bf/5SSlJzHtcbT9////+/z/1KyOdbj94zHjNbb9/f3/2u6Y9bj/5yenLXP1sbX9wAAAAAAAAAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS41LjAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+ICAgPD94cGFja2V0IGVuZD0idyI/PgH//v38+/r5+Pf29fTz8vHw7+7t7Ovq6ejn5uXk4+Lh4N/e3dzb2tnY19bV1NPS0dDPzs3My8rJyMfGxcTDwsHAv769vLu6ubi3trW0s7KxsK+urayrqqmop6alpKOioaCfnp2cm5qZmJeWlZSTkpGQj46NjIuKiYiHhoWEg4KBgH9+fXx7enl4d3Z1dHNycXBvbm1sa2ppaGdmZWRjYmFgX15dXFtaWVhXVlVUU1JRUE9OTUxLSklIR0ZFRENCQUA/Pj08Ozo5ODc2NTQzMjEwLy4tLCsqKSgnJiUkIyIhIB8eHRwbGhkYFxYVFBMSERAPDg0MCwoJCAcGBQQDAgEAACwAAAAAGQAZAAAF/2DgWGRpmlkTaIkFvWIkzzSdZY42JS8UBIqJcEicKBSZAGSSIfB8AaHBMBlYB5OpMAlJaAhOyy+LKLsgljKCyn1pMruxATGI9CARxZqZ0fgDKg5yCHZ3EAN7KQ2LjA1JVWeGiGVCV1aAjwOGPZN0dTNijlFzaqWlWlQKA5hRCBuvsLGnWgqsEwgCsLmxurAIE7a4rwLEucW7u78EGmO4x8/QAr8uj87PfhrRCmfV0djRmi/dxA4OC+foF34CE4bjAuUFBRL05zkICj0JCd0a5vQAAx4wZEuAPwoLAgYs18NCBFsEHBxQqPDJCyyAfiw7kJCiBAcE9H0BpiJARI8AQyL2IKCOZIAMFCZ6dLDvRYKYEig4SPHywAWUGu4kAHiAwqIQADs=';

    await toPng(sharingElement, {
      cacheBust: true,
      imagePlaceholder: BROKEN_IMAGE_PLACEHOLDER,
    });
    await toPng(sharingElement, {
      cacheBust: true,
      imagePlaceholder: BROKEN_IMAGE_PLACEHOLDER,
    });
    await toPng(sharingElement, {
      cacheBust: true,
      imagePlaceholder: BROKEN_IMAGE_PLACEHOLDER,
      pixelRatio: 2,
      canvasWidth: 450,
      canvasHeight: 280,
    })
      .then((png) => {
        const base64ImageData = png;
        const contentType = 'image/png';

        const byteCharacters = atob(
          base64ImageData.substring(`data:${contentType};base64,`.length),
        );
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
          const slice = byteCharacters.slice(offset, offset + 1024);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);

          byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        const blobUrl = URL.createObjectURL(blob);

        window.open(blobUrl, '_blank');
      })
      .catch((e) => alert(`toPng error: ${JSON.stringify(e)}`))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <SharingContext.Provider
      value={{ loading, onDownloadImage, onMobileShare, setSharingRef }}
    >
      {children}
    </SharingContext.Provider>
  );
};

function useSharingContext(): SharingContextType {
  const context = React.useContext(SharingContext);

  if (!context) {
    throw new Error(
      'useSharingContext must be used within a SharingContextProvider',
    );
  }

  return context;
}

export { SharingContextProvider, useSharingContext };
