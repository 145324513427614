import { ComponentStyleConfig } from '@chakra-ui/react';

const defaultButtonStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'auto',
  textAlign: 'center',
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '100%',
  color: 'button.defaultText',
  borderRadius: '6px',
  border: 'none',
  cursor: 'pointer',
  userSelect: 'none',
  outline: 'none',
  boxShadow: 'none',
  textDecoration: 'none',
};

const buttonsWithShadowAnimation = {
  transition: 'box-shadow 0.3s, transform 0.3s',
  _focus: {
    boxShadow: 'buttonShadow',
    transform: 'translateY(0)',
  },
  _active: {
    boxShadow: 'none',
    transform: 'translateY(4px)',
  },
  _disabled: {
    outline: 'none',
    boxShadow: 'buttonShadow',
    _hover: {
      boxShadow: 'buttonShadow',
      transform: 'translateY(0)',
    },
  },
};

const Button: ComponentStyleConfig = {
  baseStyle: {
    outline: 'none',
    whiteSpace: 'normal',
    _hover: {
      outline: 'none',
      boxShadow: 'none',
    },
    _focus: {
      outline: 'none',
      boxShadow: 'none',
    },
    _active: {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  sizes: {
    sm: {
      fontSize: '17px',
      px: '12px',
      py: '8px',
    },
    md: {
      fontSize: '17px',
      px: '12px',
      py: '8px',
    },
  },
  variants: {
    primary: {
      ...defaultButtonStyles,
      background: 'button.primary.bg',
      boxShadow: 'buttonShadow',
      borderRadius: '6px',
      color: 'button.primary.text',
      transition: 'background .2s linear',
      _disabled: {
        color: 'button.primary.disabledText',
      },
      _hover: {
        boxShadow: 'none',
        textDecoration: 'none',
        background: 'button.primary.activeBg',
        _disabled: {
          background: 'button.primary.disabledBg',
          outline: 'none',
          boxShadow: 'buttonShadow',
        },
      },
    },
    secondary: {
      ...defaultButtonStyles,
      background: 'button.secondary.bg',
      boxShadow: 'buttonShadow',
      borderRadius: '6px',
      border: '2px solid',
      borderColor: 'button.secondary.border',
      color: 'button.secondary.text',
      transition: 'background .2s linear',
      _hover: {
        boxShadow: 'none',
        textDecoration: 'none',
        background: 'button.secondary.activeBg',
        color: 'button.secondary.activeText',
        _disabled: {
          background: 'button.secondary.disabledBg',
          outline: 'none',
          boxShadow: 'buttonShadow',
        },
      },
    },
    ghost: {
      ...defaultButtonStyles,
      _hover: {
        outline: 'none',
        boxShadow: 'none',
        background: 'transparent',
        textDecoration: 'none',
      },
      _focus: {
        outline: 'none',
        boxShadow: 'none',
        background: 'transparent',
      },
      _active: {
        outline: 'none',
        boxShadow: 'none',
        background: 'transparent',
      },
    },
    outline: {
      ...defaultButtonStyles,
      border: '2px solid',
      borderColor: 'button.outline.border',
      transition: 'background .2s linear',
      _hover: {
        background: 'button.primary.bg',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'ghost',
  },
};

export default Button;
