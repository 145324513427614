import { FC, useEffect, useState } from 'react';

import { Box, Flex, Text, useTheme } from '@chakra-ui/react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';

import { IHeaderNavigation } from '@/hooks/useAppNav';

import { FONT_SIZE } from '../Navigation';

import { SidebarLink } from './SidebarLink';

interface SidebarItemProps extends IHeaderNavigation {
  openDropdown?: string;
  onOpenDropdown?: (label: string) => void;
}

export const SidebarItem: FC<SidebarItemProps> = ({
  onOpenDropdown,
  openDropdown,
  subLinks,
  withDropdown,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const dropdownIcon = open ? <FaChevronUp /> : <FaChevronDown />;

  const switchDropdown = () => {
    setOpen((isOpen) => !isOpen);
    onOpenDropdown?.(!open ? props.label : '');
  };

  useEffect(() => {
    if (open && openDropdown !== props.label) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDropdown]);

  return subLinks ? (
    <Box
      className={`sidebar-item ${open ? 'active' : ''}`}
      ml='30px'
      overflow='hidden'
      position='relative'
    >
      <Flex
        align='center'
        backgroundColor={open ? 'transparent' : theme.colors.white}
        color={open ? theme.colors.white : theme.colors.black}
        cursor='pointer'
        gap='16px'
        onClick={switchDropdown}
        p='16px'
        pl='24px'
        userSelect='none'
      >
        {withDropdown
          ? dropdownIcon
          : props.renderIcon?.({
              fill: open ? theme.colors.white : theme.colors.black,
            })}
        <Text
          fontSize={FONT_SIZE}
          fontWeight='700'
          lineHeight='140%'
          textTransform='capitalize'
        >
          {props.label}
        </Text>
      </Flex>

      {subLinks?.map((subLink, idx) => (
        <SidebarLink
          {...subLink}
          className='sidebar-subitem'
          display={open ? 'block' : 'none'}
          key={idx}
          ml='0'
          selected={open}
        />
      ))}
    </Box>
  ) : (
    <SidebarLink {...props} selected={!openDropdown && props.selected} />
  );
};
