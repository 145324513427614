import { useMemo } from 'react';

import { usePlayerStats } from '@/hooks/usePlayerStats';

import { useGetAllPlayersQuery } from '@/services/openApi.service';

import { useFiltersStore } from '@/store/hooks/useFiltersStore.hook';

import { PlayerType, TeamOptionType } from '@/types/domain';

import { getTeamList } from '@/utils/getTeamList';

import { useJWT } from './useJWT.hook';

export const usePlayers = () => {
  const { jwt } = useJWT();
  const { data, isLoading, isSuccess, error } = useGetAllPlayersQuery(
    undefined,
    { skip: !jwt },
  );
  const { filters } = useFiltersStore();

  const players = useMemo(() => data?.skills_players || [], [data]);

  const prePickedPlayers = useMemo<PlayerType[]>(
    () =>
      (data?.pre_picked_skills_players || []).map((x) => ({
        ...x,
        isPrePicked: true,
      })),
    [data],
  );

  const mappedPlayers = useMemo(
    () =>
      players.map((player) => ({
        ...player,
        isPrePicked: false,
      })),
    [players],
  ) as PlayerType[];

  const allPlayers = useMemo(
    () => [...prePickedPlayers, ...mappedPlayers],
    [prePickedPlayers, mappedPlayers],
  );

  const { statsOptions } = usePlayerStats({
    role: filters.role,
  });

  const teams = useMemo(
    () => getTeamList(allPlayers),
    [allPlayers],
  ) as TeamOptionType[];

  return {
    allPlayers,
    error,
    isLoading,
    isSuccess,
    players: mappedPlayers,
    prePickedPlayers,
    statsOptions,
    teams,
  };
};
