import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@/store/hooks/index';
import { SET_USER, UserSliceIStateType } from '@/store/slices/user.slice';

export const useUserStore = () => {
  const { user } = useAppSelector((store) => store.user);
  const dispatch = useAppDispatch();

  const setUser = useCallback(
    (data: UserSliceIStateType['user']) => dispatch(SET_USER(data)),
    [dispatch],
  );

  return { user, setUser };
};
