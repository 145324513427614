import { FC, SVGProps } from 'react';

export const HomeIcon: FC<SVGProps<any>> = ({ fill = '#111111' }) => {
  return (
    <svg
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        clipRule='evenodd'
        d='M12.4717 4.94734C12.1815 4.74782 11.8024 4.75626 11.5383 4.94044L11.5281 4.94756L5.10238 9.36355C5.10203 9.3638 5.10167 9.36404 5.10132 9.36429C4.88849 9.51162 4.8 9.70961 4.8 9.89928V19.2H7.7V14C7.7 12.7297 8.72974 11.7 10 11.7H14C15.2703 11.7 16.3 12.7297 16.3 14V19.2H19.2V9.89928C19.2 9.70958 19.1115 9.51156 18.8986 9.36422C18.8983 9.364 18.8979 9.36378 18.8976 9.36355L12.4717 4.94734ZM19.92 7.88209L13.4914 3.46408C12.5914 2.84531 11.3957 2.84531 10.5086 3.46408L4.08 7.88209C3.39857 8.35236 3 9.09488 3 9.89928V19.7625C3 20.4431 3.57857 21 4.28571 21H9.5V14C9.5 13.7239 9.72386 13.5 10 13.5H14C14.2761 13.5 14.5 13.7239 14.5 14V21H19.7143C20.4214 21 21 20.4431 21 19.7625V9.89928C21 9.09488 20.6014 8.35236 19.92 7.88209Z'
        fill={fill}
        fillRule='evenodd'
      />
    </svg>
  );
};
