import { lazy } from 'react';

import { RoutesEnum } from '@/enums/routes.enum';

import { IRoutes } from '@/routes/types';

const MyAllStars = lazy(() => import('@/pages/MyAllStars'));
const OnboardingPage = lazy(() => import('@/pages/Onboarding'));
const PlayerPage = lazy(() => import('@/pages/Player'));
const SearchPage = lazy(() => import('@/pages/Search'));
const VotePage = lazy(() => import('@/pages/Vote'));
const ComingSoonPage = lazy(() => import('@/pages/ComingSoon'));
const SubmittedPage = lazy(() => import('@/pages/Submitted'));
const PrePickedPlayersPage = lazy(() => import('@/pages/PrePickedPlayers'));
const OfficialRulesPage = lazy(() => import('@/pages/OfficialRules'));
const VotingEndedPage = lazy(() => import('@/pages/VotingEnded'));

export const routesWithLayout: IRoutes[] = [
  {
    path: RoutesEnum.myAllStars,
    element: <MyAllStars />,
  },
  {
    path: RoutesEnum.player,
    element: <PlayerPage />,
  },
  {
    path: RoutesEnum.home,
    element: <VotePage />,
  },
  {
    path: RoutesEnum.playerProfileDesktop,
    element: <VotePage />, // Desktop with modal Player profile
  },
  {
    path: RoutesEnum.search,
    element: <SearchPage />,
  },
  {
    path: RoutesEnum.searchSkills,
    element: <SearchPage />,
  },
  {
    path: RoutesEnum.prePickedPlayers,
    element: <PrePickedPlayersPage />,
  },
  {
    path: RoutesEnum.prePickedPlayersWithId,
    element: <PrePickedPlayersPage />, // Desktop with modal Player profile
  },
  {
    path: RoutesEnum.submitted,
    element: <SubmittedPage />,
  },
  {
    path: RoutesEnum.rules,
    element: <OfficialRulesPage />,
  },
  {
    path: RoutesEnum.onboarding,
    element: <OnboardingPage />,
  },
];

export const routesWithoutLayout = [
  {
    path: RoutesEnum.coming,
    element: <ComingSoonPage />,
  },
  {
    path: RoutesEnum.votingEnded,
    element: <VotingEndedPage />,
  },
];
