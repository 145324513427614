import React, { useEffect, useState } from 'react';

import { Image, ImageProps } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { RoutesEnum } from '@/enums/routes.enum';

import { useLang } from '@/hooks/useLang.hook';

import { useUserStore } from '@/store/hooks/useUserStore.hook';

type LogoImageProps = {
  isClickable?: boolean;
} & ImageProps;
const LogoImage: React.FC<LogoImageProps> = ({
  isClickable = false,
  ...props
}) => {
  const { user } = useUserStore();
  const { lang } = useLang();
  const navigate = useNavigate();
  const [imageSrc, setImageSrc] = useState<any>(null);

  useEffect(() => {
    try {
      setImageSrc(
        require(
          `@/assets/icons/logos/skills_logo_${
            user?.country === 'US' ? 'usa' : 'int'
          }_mobile_${lang}.svg`,
        ),
      );
    } catch (e) {
      setImageSrc(
        require(
          `@/assets/icons/logos/skills_logo_${
            user?.country === 'US' ? 'usa' : 'int'
          }_mobile_en.svg`,
        ),
      );
    }
  }, [lang, user?.country]);

  const goToHome = () => {
    navigate(RoutesEnum.home);
  };

  return imageSrc ? (
    <Image
      {...(isClickable ? { cursor: 'pointer', onClick: goToHome } : {})}
      src={imageSrc}
      {...props}
    />
  ) : null;
};

export default LogoImage;
