import { Suspense } from 'react';

import { Outlet, Route, Routes } from 'react-router-dom';

import { routesWithLayout, routesWithoutLayout } from '@/routes';

import Layout from '@/components/Layout';

import { PageLoader } from '@/elements/PageLoader';

import withSplash from '@/hocs/withSplash.hoc';

import { useGTMPageViewTrack } from '@/hooks/useGTMPageViewTrack.hook';

const App = () => {
  useGTMPageViewTrack();
  return (
    <Routes>
      <Route
        element={
          <Suspense fallback={<PageLoader />}>
            <Outlet />
          </Suspense>
        }
      >
        {routesWithoutLayout.map((route) => (
          <Route element={route.element} key={route.path} path={route.path} />
        ))}

        <Route element={<Layout />}>
          {routesWithLayout.map((route) => (
            <Route element={route.element} key={route.path} path={route.path} />
          ))}
        </Route>
      </Route>
    </Routes>
  );
};

export default withSplash(App);
