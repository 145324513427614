import { useEffect, useMemo } from 'react';

import { useJWT } from '@/hooks/useJWT.hook';

import { useGetVoteDetailsQuery } from '@/services/api.service';

import { useUserStore } from '@/store/hooks/useUserStore.hook';

import { usePlayers } from './usePlayers.hook';

export const useVoteDetails = () => {
  const { jwt } = useJWT();
  const { isSuccess } = usePlayers();
  const { setUser } = useUserStore();

  const { data: voteDetails, isFetching } = useGetVoteDetailsQuery(undefined, {
    skip: !jwt || !isSuccess,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (voteDetails?.user) {
      setUser(voteDetails?.user);
    }
  }, [setUser, voteDetails?.user]);

  const isAllBallotsVotedToday = useMemo(
    () =>
      typeof voteDetails?.votes_left === 'number' &&
      voteDetails?.votes_left === 0,
    [voteDetails?.votes_left],
  );

  return { isAllBallotsVotedToday, voteDetails, isFetching };
};
