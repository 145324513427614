import { Text } from '@chakra-ui/react';
import { t } from 'i18next';

import { tKeys } from '@/i18n';

import { useSettings } from '@/hooks/useSettings.hook';

export const AbbreviatedRules = () => {
  const { isDesktop } = useSettings();

  return (
    <Text fontSize={isDesktop ? '10px' : '9px'} fontWeight='500'>
      {t(tKeys.noPurchaseNecessarySkills)}
    </Text>
  );
};
