import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiTagsEnum } from '@/enums/apiTags.enum';

import { AllPlayersRes } from '@/types/domain';

import { API_BASE_URL } from '@/utils/constants';

const baseQuery = fetchBaseQuery({ baseUrl: API_BASE_URL });

export const api = createApi({
  reducerPath: 'mainApi',
  tagTypes: Object.values(ApiTagsEnum),
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getAllPlayers: builder.query<AllPlayersRes, void>({
      query: () =>
        // 'https://all-star-vote-dev.azureedge.net/nhl-allstar-players/players.json',
        `https://all-star-vote-dev.azureedge.net/nhl-allstar-players/players.json?date=${Date.now()}`,
      providesTags: [ApiTagsEnum.GET_PLAYERS],
    }),
  }),
});

export const { useGetAllPlayersQuery } = api;
