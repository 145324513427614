import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import { checkIsGoalieIndex } from '@/components/Ballot/helpers';

import { PlayerPositionEnum } from '@/enums/PlayerPosition.enum';
import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage.hook';
import { useSettings } from '@/hooks/useSettings.hook';
import { useToast, CustomToastType } from '@/hooks/useToast.hook';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  CLEAR_BALLOT,
  IPlayersState,
  MAX_ALL_PLAYERS,
  REMOVE_SELECTED_PLAYER,
  SET_IS_NEW_BALLOT,
  SET_IS_SKILLS_BALLOT_IN_USE,
  SET_LAST_CLICKED_CARD_INDEX,
  SET_SELECTED_PLAYER,
  SET_SELECTED_PLAYERS,
} from '@/store/slices/players.slice';

import { PlayerType } from '@/types/domain';

export const usePlayersStore = () => {
  const { isDesktop } = useSettings();
  const { t } = useTranslation();
  const toast = useToast();

  const { removeItem: removeSavedSelectedPlayersFromLS } = useLocalStorage<
    IPlayersState['selectedSkills']
  >(StorageKeysEnum.selectedSkills);

  const adaptiveToast: CustomToastType = useCallback(
    (message, type, params) =>
      toast(message, type, { ...params, isModalOnDesktop: true }),
    [toast],
  );

  const {
    isNewBallot,
    isSkillsBallotInUse,
    selectedSkills,
    lastClickedCardIndex,
  } = useAppSelector((store) => store.players);
  const dispatch = useAppDispatch();

  const setIsNewBallot = (value: boolean) => dispatch(SET_IS_NEW_BALLOT(value));
  const clearBallot = () => dispatch(CLEAR_BALLOT());

  const setLastClickedCardIndex = useCallback(
    (idx: number) => dispatch(SET_LAST_CLICKED_CARD_INDEX(idx)),
    [dispatch],
  );
  const setSelectedPlayers = useCallback(
    (players: IPlayersState['selectedSkills']) =>
      dispatch(SET_SELECTED_PLAYERS(players)),
    [dispatch],
  );

  const findSelectedPlayerById = useCallback(
    (playerId: number) =>
      Object.values(selectedSkills)?.find((x) => x?.player_id === playerId),
    [selectedSkills],
  );

  const removeSelectedPlayerById = useCallback(
    (playerId: number) => dispatch(REMOVE_SELECTED_PLAYER(playerId)),
    [dispatch],
  );

  const totalSelectedPlayers = useMemo(() => {
    return Object.values(selectedSkills)?.reduce((acc, item) => {
      return item ? (acc += 1) : acc;
    }, 0);
  }, [selectedSkills]);

  const playerAddedToast = useCallback(
    () =>
      toast(
        t(tKeys.playerAdded, {
          total: totalSelectedPlayers + 1,
          max: MAX_ALL_PLAYERS,
        }),
        'success',
      ),
    [t, toast, totalSelectedPlayers],
  );

  const setSelectedPlayer = useCallback(
    (player: PlayerType) => {
      const keys = Object.keys(selectedSkills);

      if (totalSelectedPlayers >= MAX_ALL_PLAYERS) {
        adaptiveToast(t(tKeys.rosterAlreadyFull), 'error');
        return;
      }

      if (lastClickedCardIndex) {
        for (const key of keys) {
          const fieldIndex = parseInt(key);
          if (selectedSkills[fieldIndex] === null) {
            dispatch(SET_SELECTED_PLAYER({ ...player, cardIndex: fieldIndex }));
            playerAddedToast();
            return;
          }
        }

        dispatch(SET_SELECTED_PLAYER(player));
        playerAddedToast();
        return;
      }

      if (!lastClickedCardIndex) {
        for (const key of keys) {
          const fieldIndex = parseInt(key);

          if (selectedSkills[fieldIndex] === null) {
            dispatch(SET_SELECTED_PLAYER({ ...player, cardIndex: fieldIndex }));
            playerAddedToast();
            return;
          }
        }
      }
    },
    [
      adaptiveToast,
      dispatch,
      lastClickedCardIndex,
      playerAddedToast,
      selectedSkills,
      t,
      totalSelectedPlayers,
    ],
  );

  const selectPlayerHandler = (player: PlayerType) => {
    setSelectedPlayer(player);

    // if (!isDesktop) {
    //   navigate(RoutesEnum.home);
    //   // Clear filters
    //   setEligibleOnly(false);
    //   setFilterByTeam(null);
    //   setSorting(null);
    //   setSearchInputValue('');
    // }
  };

  const setIsSkillsBallotInUse = (value: boolean) =>
    dispatch(SET_IS_SKILLS_BALLOT_IN_USE(value));

  return {
    clearBallot,
    isNewBallot,
    isSkillsBallotInUse,
    selectedSkills,
    lastClickedCardIndex,
    setIsNewBallot,
    setSelectedPlayer,
    setLastClickedCardIndex,
    findSelectedPlayerById,
    removeSelectedPlayerById,
    removeSavedSelectedPlayersFromLS,
    totalSelectedPlayers,
    selectPlayerHandler,
    setSelectedPlayers,
    setIsSkillsBallotInUse,
  };
};
