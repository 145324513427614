import { FC } from 'react';

import { Flex, Link as ChakraLink } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { IHeaderNavigation } from '@/hooks/useAppNav';

import { FONT_SIZE } from '.';

const Option: FC<IHeaderNavigation> = ({ label, linkData }) => {
  const { to, isExternal } = { ...linkData };

  const linkProps = {
    ...(!isExternal && { as: ReactRouterLink }),
    [isExternal ? 'href' : 'to']: to,
    isExternal,
  };

  return (
    <ChakraLink
      _hover={{ bg: '#DA1A32', color: '#FFFFFF' }}
      color='#1D1D1B'
      display='flex'
      p='16px 24px'
      {...linkProps}
    >
      {label}
    </ChakraLink>
  );
};

interface INavDropdown extends Pick<IHeaderNavigation, 'subLinks'> {
  isOpen: boolean;
}

const NavDropdown: FC<INavDropdown> = ({ isOpen, subLinks }) => (
  <Flex
    backdropFilter='blur(4px)'
    bg='rgba(255, 255, 255, 0.85)'
    borderRadius='0 0 8px 8px'
    boxShadow='0px 4px 16px 0px rgba(255, 255, 255, 0.25)'
    direction='column'
    display={isOpen ? 'block' : 'none'}
    fontSize={FONT_SIZE}
    left='0'
    overflow='hidden'
    position='absolute'
    top='100%'
    w='100%'
  >
    {subLinks?.map((link, idx) => <Option key={idx} {...link} />)}
  </Flex>
);

export default NavDropdown;
