import { FC, SVGProps } from 'react';

export const VoteIcon: FC<SVGProps<{ fill?: string }>> = ({
  fill = '#111111',
}) => {
  return (
    <svg
      fill='none'
      height='25'
      viewBox='0 0 25 25'
      width='25'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.5 13.0276H17.82L15.82 15.0276H17.73L19.5 17.0276H5.5L7.28 15.0276H9.33L7.33 13.0276H6.5L3.5 16.0276V20.0276C3.5 21.1276 4.39 22.0276 5.49 22.0276H19.5C20.6 22.0276 21.5 21.1376 21.5 20.0276V16.0276L18.5 13.0276ZM17.5 7.97763L12.55 12.9276L9.01 9.38763L13.96 4.43763L17.5 7.97763ZM13.26 2.31763L6.89 8.68763C6.5 9.07763 6.5 9.70763 6.89 10.0976L11.84 15.0476C12.23 15.4376 12.86 15.4376 13.25 15.0476L19.61 8.68763C20 8.29763 20 7.66763 19.61 7.27763L14.66 2.32763C14.28 1.92763 13.65 1.92763 13.26 2.31763Z'
        fill={fill}
      />
    </svg>
  );
};
