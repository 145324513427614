import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PlayerType } from '@/types/domain';

export interface IPlayersState {
  lastClickedCardIndex: number;
  isNewBallot: boolean;
  selectedSkills: { [key: number]: PlayerType | null };
  isSkillsBallotInUse: boolean;
}

const initialState = {
  lastClickedCardIndex: 0,
  isNewBallot: false,
  selectedSkills: {
    1: null,
    2: null,
  },
  isSkillsBallotInUse: false,
} as IPlayersState;

const playersSlice = createSlice({
  name: 'players',
  initialState,
  reducers: {
    CLEAR_BALLOT(state) {
      state.selectedSkills = {
        1: null,
        2: null,
      };
    },
    SET_IS_NEW_BALLOT(state, action) {
      state.isNewBallot = action.payload;
    },
    SET_LAST_CLICKED_CARD_INDEX(state, action: PayloadAction<number>) {
      state.lastClickedCardIndex = action.payload;
    },
    SET_SELECTED_PLAYER(
      state,
      action: PayloadAction<PlayerType & { cardIndex?: number }>,
    ) {
      const { cardIndex } = action.payload;

      state.selectedSkills[cardIndex || state.lastClickedCardIndex] =
        action.payload;
      state.lastClickedCardIndex = 0;
    },
    SET_SELECTED_PLAYERS(
      state,
      action: PayloadAction<IPlayersState['selectedSkills']>,
    ) {
      state.selectedSkills = action.payload;
    },
    REMOVE_SELECTED_PLAYER(state, action: PayloadAction<number>) {
      const foundSelectedPlayer = Object.entries(state.selectedSkills)?.find(
        ([_, value]) => value?.player_id === action.payload,
      );
      if (foundSelectedPlayer?.length) {
        state.selectedSkills[Number(foundSelectedPlayer[0])] = null;
      }
    },
    SET_IS_SKILLS_BALLOT_IN_USE(state, action) {
      state.isSkillsBallotInUse = action.payload;
    },
  },
});

export default playersSlice.reducer;
export const {
  CLEAR_BALLOT,
  SET_IS_NEW_BALLOT,
  SET_SELECTED_PLAYER,
  SET_LAST_CLICKED_CARD_INDEX,
  REMOVE_SELECTED_PLAYER,
  SET_SELECTED_PLAYERS,
  SET_IS_SKILLS_BALLOT_IN_USE,
} = playersSlice.actions;

export const MAX_ALL_PLAYERS = 2;
export const SKILLS_CARD_COUNT = 2;
