import { ChakraProvider } from '@chakra-ui/react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import '@/i18n/i18n';

import App from '@/App';
import { setupStore } from '@/store';
import theme from '@/theme';

import Fonts from '@/assets/fonts';

import { LocalStorageProvider } from '@/contexts/localStorage.context';
import { SharingContextProvider } from '@/contexts/sharing.context';
import { SidebarContextProvider } from '@/contexts/sidebar.context';

import { AppProvider } from '@/providers/App.provider';
import { AuthProvider } from '@/providers/Auth.provider';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const store = setupStore();

root.render(
  <ReduxProvider store={store}>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <LocalStorageProvider>
          <SharingContextProvider>
            <SidebarContextProvider>
              <AuthProvider>
                <AppProvider>
                  <Fonts />
                  <App />
                </AppProvider>
              </AuthProvider>
            </SidebarContextProvider>
          </SharingContextProvider>
        </LocalStorageProvider>
      </BrowserRouter>
    </ChakraProvider>
  </ReduxProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
