import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import { SHARE_URL } from '@/utils/constants';

const SHARE_LINK = `${SHARE_URL}`;
const SHARE_FILE_NAME = 'my-nhl-all-stars-2024-vote.png';

export const useSharingOptions = () => {
  const { t } = useTranslation();

  const shareTitle = t(tKeys.myNASV);

  return {
    shareFileName: SHARE_FILE_NAME,
    shareLink: SHARE_LINK,
    shareTitle,
  };
};
