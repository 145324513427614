import React from 'react';

import { Box, Container, Grid, Image } from '@chakra-ui/react';

import NHLShield from '@/assets/icons/nhl-shield.svg';
import footer from '@/assets/images/NHL_footer_mobile.jpg';

import { useSettings } from '@/hooks/useSettings.hook';

import { usePlayersStore } from '@/store/hooks/usePlayersStore.hook';

import { AbbreviatedRules } from './AbbreviatedRules';

const DesktopFooter = () => {
  const { totalSelectedPlayers } = usePlayersStore();

  return (
    <Container maxW='1024px'>
      <Grid
        gap='20px'
        gridTemplateColumns='1fr 9fr'
        p='25px'
        pb={totalSelectedPlayers >= 1 ? '90px' : '25px'}
      >
        <Image alt='' h='80px' mb='16px' mx='auto' src={NHLShield} />

        <AbbreviatedRules />
      </Grid>
    </Container>
  );
};

const MobileFooter = () => {
  return (
    <Box pb='60px'>
      <Image alt='' mb='16px' mx='auto' src={NHLShield} />

      <AbbreviatedRules />
    </Box>
  );
};

export const Footer: React.FC = () => {
  const { isDesktop } = useSettings();

  return (
    <Box as='footer' backgroundColor='black' color='#ccc'>
      {isDesktop ? null : <Image src={footer} w='100%' />}

      <Box pb='25px' pt='10px' px='25px'>
        {isDesktop ? <DesktopFooter /> : <MobileFooter />}
      </Box>
    </Box>
  );
};
