import { createSlice } from '@reduxjs/toolkit';

import { PlayerRoleEnum } from '@/enums/PlayerRole.enum';
import { VotePlayersFilterEnum } from '@/enums/votePlayersFilter.enum';

import { SeasonStats } from '@/types/domain';

export interface IFiltersState {
  hidePreSelectedPlayers: boolean;
  team_id: number | null;
  sorting: keyof SeasonStats | null;
  role: PlayerRoleEnum | null;
  searchInputValue: string;
  voteRoleFilter: VotePlayersFilterEnum;
}

const initialState = {
  hidePreSelectedPlayers: false,
  team_id: null,
  sorting: null,
  role: PlayerRoleEnum.skater,
  searchInputValue: '',
  voteRoleFilter: VotePlayersFilterEnum.all,
} as IFiltersState;

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    SET_ROLE(state, action) {
      state.role = action.payload;
    },
    SET_ELIGIBLE_ONLY(state, action) {
      state.hidePreSelectedPlayers = action.payload;
    },
    SET_TEAM_ID(state, action) {
      state.team_id = action.payload;
    },
    SET_SORTING(state, action) {
      state.sorting = action.payload;
    },
    SET_SEARCH_INPUT_VALUE(state, action) {
      state.searchInputValue = action.payload;
    },
    SET_VOTE_ROLE_FILTER(state, action) {
      state.voteRoleFilter = action.payload;
    },
  },
});

export default filtersSlice.reducer;
export const {
  SET_ELIGIBLE_ONLY,
  SET_ROLE,
  SET_SEARCH_INPUT_VALUE,
  SET_SORTING,
  SET_TEAM_ID,
  SET_VOTE_ROLE_FILTER,
} = filtersSlice.actions;
